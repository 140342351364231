import { configureStore } from "@reduxjs/toolkit";
import { urlPathSliceReducer } from "../Features/urlPath";

const store = configureStore({
  reducer: {
    urlPath: urlPathSliceReducer,
  },
});

export default store;
