import React, { useState } from "react";

import companyLogo from "../../../Assets/company-logo.png";
import companyLogoText from "../../../Assets/company-logo-text.png";

import { RiMenu3Fill } from "react-icons/ri";
import { MdOutlineCloseFullscreen } from "react-icons/md";

import "./index.css";
import { headerItems } from "../../../Constants/HeaderItems";
import { HeaderItems } from "../HeaderItem";

export const Header = () => {
  const [isOpen, setOpen] = useState(false);

  return (
    <div className="header-main">
      <img
        src={companyLogo}
        alt="company-logo"
        className="header-company-logo"
      />
      <img
        src={companyLogoText}
        alt="company-logo-text"
        className="header-company-logo-text"
      />

      {isOpen ? (
        <MdOutlineCloseFullscreen
          color="#fff"
          size={20}
          className="header-close-button"
          style={{ zIndex: "1000" }}
          onClick={() => setOpen(!isOpen)}
        />
      ) : (
        <RiMenu3Fill
          size={20}
          color={"#293A60"}
          strokeWidth={1}
          className="header-mobile-menu"
          onClick={() => setOpen(!isOpen)}
        />
      )}
      <nav
        className="header-items-card"
        style={{
          left: isOpen ? "35%" : "100%",
          position: isOpen && "absolute",
        }}>
        {headerItems.map((item, index) => (
          <HeaderItems key={index} item={item} />
        ))}
      </nav>
    </div>
  );
};
