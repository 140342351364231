import { Switch, Route } from "react-router-dom";

import "./App.css";

import InitialPage from "./PageContent/Home/InitialPage";
import ConnectOptions from "./PageContent/Home/ConnectOptions";
import PersonalHealthCircles from "./PageContent/Home/PersonalHealthCircles";
import DeviceFeatures from "./PageContent/Home/DeviceFeatures";
import HowItWorks from "./PageContent/Home/HowItWorks";
import MobileAppSuggestion from "./PageContent/Home/MobileAppSuggestion";
// import GetInTouch from "./PageContent/Home/GetInTouch";
import Footer from "./Components/Footer";
import Home from "./Pages/Home";

function App() {
  return (
    <Switch>
      <Route exact path="/" component={Home} />
    </Switch>
  );
}

export default App;
