import React, { Component } from "react";

import "./index.css";

import { PiMouseSimple } from "react-icons/pi";

import { Header } from "../../../Components/Header/Main";
import sideDesign1 from "../../../Assets/side-design-1.png";
import sideDesign2 from "../../../Assets/side-design-2.png";
import doctorPatient from "../../../Assets/doctor-patient.png";
import nextArrow from "../../../Assets/next-arrow.svg";

class InitialPage extends Component {
  render() {
    return (
      <div className="initial-page-main">
        <Header />
        <div className="initial-page-content">
          <img
            src={sideDesign1}
            alt="design-1"
            className="initial-page-side-design-1"
          />
          <div className="initial-page-middle-card">
            <h1 className="initial-page-main-heading">
              Your{" "}
              <span className="initial-page-main-heading-span">Health</span>,
              Your{" "}
              <span className="initial-page-main-heading-span">Family</span>,
              Your{" "}
              <span className="initial-page-main-heading-span">Doctor</span>
            </h1>
            <h2 className="initial-page-sub-heading">Your Circle</h2>
            <p className="initial-page-paragraph">
              Kshemam is creating a new way to take care of yourself. Monitor
              your health, share with your family, and <br /> get help when
              needed.
            </p>
            <div className="initial-page-button-card">
              <button type="button" className="initial-page-know-more-button">
                Know More
              </button>
              <button type="button" className="initial-page-get-start-button">
                Get Started
                <img src={nextArrow} alt="next-arrow" />
              </button>
            </div>
            <PiMouseSimple size={20} color={"#293A60"} />
            <p className="initial-page-scroll-more-text">Scroll for more</p>
            <div className="initial-page-doctor-patient-img-card">
              <img
                src={doctorPatient}
                alt="doctor-patient"
                className="initial-page-doctor-patient-img"
              />
            </div>
          </div>
          <img
            src={sideDesign2}
            alt="design-2"
            className="initial-page-side-design-2"
          />
        </div>
      </div>
    );
  }
}

export default InitialPage;
