import React, { useEffect, useMemo, useState } from "react";

import "./index.css";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";

const activeStyle = {
  fontWeight: "bold",
};

export const HeaderItems = ({ item }) => {
  const { name, path } = item;
  const currentPath = useSelector((state) => state.urlPath.currentPath);

  return (
    <NavLink exact to={path} activeStyle={activeStyle} className="header-item">
      {name}
      {currentPath === path && <span className="header-item-underline"></span>}
    </NavLink>
  );
};
