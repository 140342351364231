import { FaSquareTwitter } from "react-icons/fa6";
import { FaFacebookSquare } from "react-icons/fa";
import { BsLinkedin } from "react-icons/bs";
import { RiInstagramFill } from "react-icons/ri";

export const mediaIconsList = [
  { name: "twitter", icon: FaSquareTwitter },
  { name: "facebook", icon: FaFacebookSquare },
  { name: "linkedin", icon: BsLinkedin },
  { name: "instagram", icon: RiInstagramFill },
];
