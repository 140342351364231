import { Component } from "react";

import "./index.css";
import { howItWorksList } from "../../../Constants/HowItWorks";

export default class HowItWorks extends Component {
  render() {
    return (
      <div className="how-works-main">
        <h1 className="how-works-main-heading">
          How it <span className="how-works-main-heading-span"> Works</span>
        </h1>
        <ul className="how-works-list-card">
          {howItWorksList.map((item, index) => (
            <li key={index} className="how-works-list-item-card">
              <img
                src={item.image}
                alt={item.work}
                className="how-works-list-item-image"
              />
              <div className="how-works-list-item-text-card">
                <div className="how-works-list-item-num-card">{index + 1}</div>
                <p className="how-works-list-item-work-text">{item.work}</p>
              </div>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}
