import { Component } from "react";

import { connect } from "react-redux";

import InitialPage from "../../PageContent/Home/InitialPage";

import { urlPathSliceActions } from "../../Redux/Features/urlPath";
import ConnectOptions from "../../PageContent/Home/ConnectOptions";
import DeviceFeatures from "../../PageContent/Home/DeviceFeatures";
import HowItWorks from "../../PageContent/Home/HowItWorks";
import PersonalHealthCircles from "../../PageContent/Home/PersonalHealthCircles";
import MobileAppSuggestion from "../../PageContent/Home/MobileAppSuggestion";
//import GetInTouch from "../../PageContent/Home/GetInTouch";
import Footer from "../../Components/Footer";

import "./index.css";

const { handleUrlPath } = urlPathSliceActions;

class Home extends Component {
  componentDidMount() {
    const { match } = this.props;
    const { path } = match;
    this.props.dispatch(handleUrlPath(path));
  }
  render() {
    return (
      <div className="home-main">
        <InitialPage />
        <ConnectOptions />
        <DeviceFeatures />
        <HowItWorks />
        <PersonalHealthCircles />
        <MobileAppSuggestion />
        <Footer />
      </div>
    );
  }
}

const urlPathReduxStateToProps = (state) => ({
  currentPath: state.urlPath.currentPath,
});

export default connect(urlPathReduxStateToProps)(Home);
