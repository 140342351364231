import { Component } from "react";

import "./index.css";

import mobileAppBg from "../../../Assets/mobile-app-bg.png";
import mobileImage from "../../../Assets/mobile.png";
import playStoreImage from "../../../Assets/playstore.png";
import nextArrowWhite from "../../../Assets/next-arrow-white.svg";

export default class MobileAppSuggestion extends Component {
  render() {
    return (
      <div className="mobile-app-main">
        <div className="mobile-app-mobile-view-card">
          <img
            src={mobileImage}
            alt="mobile-img"
            className="mobile-app-image"
          />
          <div className="mobile-app-text-card">
            <h3 className="mobile-app-heading-1">Stay connected always</h3>
            <h1 className="mobile-app-heading-2">
              With our 24/7 monitoring App
            </h1>
            <p className="mobile-app-paragraph">
              Download the app by clicking on the google play button{" "}
            </p>
            <div className="mobile-app-button-card">
              <button className="mobile-app-playstore-button">
                <a href="https://play.google.com/store/apps/details?id=com.mobile.kshemam&pcampaignid=web_share" target="_blank">
                <img
                  src={playStoreImage}
                  alt="playstore-img"
                  className="mobile-app-playstore-button-image"
                  
                />
               </a>
              </button>
              <button className="mobile-app-know-more-button">
                Know More
                <img
                  src={nextArrowWhite}
                  alt="next-arrow-white"
                  className="mobile-app-know-more-button-next-arrow"
                />
              </button>
            </div>
          </div>
        </div>
        <div className="mobile-app-content-bg">
          <img
            src={mobileImage}
            alt="mobile-img"
            className="mobile-app-image"
          />
          <div className="mobile-app-text-card">
            <h3 className="mobile-app-heading-1">Stay connected always</h3>
            <h1 className="mobile-app-heading-2">
              With our 24/7 monitoring App
            </h1>
            <p className="mobile-app-paragraph">
              Download the app by clicking on the google play button{" "}
            </p>
            <div className="mobile-app-button-card">
            <a href="https://play.google.com/store/apps/details?id=com.mobile.kshemam&pcampaignid=web_share" target="_blank">
                <img
                  src={playStoreImage}
                  alt="playstore-img"
                  className="mobile-app-playstore-button-image"
                  
                />
               </a>
              <button className="mobile-app-know-more-button">
                Know More
                <img
                  src={nextArrowWhite}
                  alt="next-arrow-white"
                  className="mobile-app-know-more-button-next-arrow"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
