import fallDetection from "../../Assets/fall-detection.png";
import bloodPressure from "../../Assets/blood-pressure.png";
import bloodGlucose from "../../Assets/blood-glucose.png";
import spo2 from "../../Assets/spo2.png";
import heartRate from "../../Assets/heart-rate.png";
import stepCount from "../../Assets/step-count.png";
import sleep from "../../Assets/sleep.png";

export const deviceFeaturesList = [
  {
    feature: "Heart Rate",
    description: "Monitor your pulse",
    image: heartRate,
  },
  {
    feature: "Blood Oxygen",
    description: "Track blood oxygen levels",
    image: spo2,
  },
  {
    feature: "Step Count",
    description: "Manage your activity",
    image: stepCount,
  },
  {
    feature: "Sleep",
    description: "Analyze your sleep patterns",
    image: sleep,
  },
  {
    feature: "Blood Glucose",
    description: "Check your sugar levels",
    image: bloodGlucose,
  },
  {
    feature: "Blood Pressure",
    description: "Track your blood pressure ",
    image: bloodPressure,
  },
];
