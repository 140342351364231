import { Component } from "react";

import "./index.css";

import runningMan from "../../../Assets/running-man.png";
import { deviceFeaturesList } from "../../../Constants/DeviceFeatures";

export default class DeviceFeatures extends Component {
  render() {
    return (
      <div className="device-features-main">
        <div>
          <img
            src={runningMan}
            alt="running-man"
            className="device-features-running-man-image"
          />
        </div>
        <div className="device-features-between-gap"></div>
        <div className="device-features-text-content-card">
          <h1 className="device-features-main-heading">
            Stay Connected{" "}
            <span className="device-features-main-heading-span-1">Stay </span>
            <span className="device-features-main-heading-span-2">Safe</span>
          </h1>
          <ul className="device-features-list-card">
            {deviceFeaturesList.map((item, index) => (
              <li key={index} className="device-features-list-item">
                <img
                  src={item.image}
                  alt={item.feature}
                  className="device-features-list-item-image"
                />
                <h3 className="device-features-list-item-feature">
                  {item.feature}
                </h3>
                <p className="device-features-list-item-description">
                  {item.description}
                </p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }
}
