import { Component } from "react";

import { HiPlusSmall } from "react-icons/hi2";

import watchDevice from "../../../Assets/watch-device.png";

import "./index.css";
import { connectOptionsList } from "../../../Constants/ConnectOptions";

export default class ConnectOptions extends Component {
  render() {
    return (
      <div className="connect-options-main">
        <div className="connect-options-content-card">
          <div className="connect-options-text-card">
            <h1 className="connect-options-main-heading">
              Stay connected with your{" "}
              <span className="connect-options-main-heading-span">Loved</span>{" "}
              ones
            </h1>
            <p className="connect-options-main-paragraph">
              Taking remote health monitoring to a whole new level
            </p>
          </div>
          <img
            src={watchDevice}
            alt="watch-device"
            className="connect-options-watch-device-img"
          />
        </div>
        <ul className="connect-options-list-card">
          {connectOptionsList.map((item, index) => (
            <li key={index} className="connect-options-list-item">
              <div>
                <img
                  src={item.image}
                  alt={item.option}
                  className="connect-options-list-image"
                />
              </div>
              <div className="connect-options-list-text-card">
                <p className="connect-options-list-option-text">
                  {item.option}
                </p>
                <div className="connect-options-learn-more-card">
                  <p className="connect-options-list-learn-more-text">
                    Learn More
                  </p>
                  <button
                    type="button"
                    className="connect-options-learn-more-button">
                    <HiPlusSmall color="#E18C5E" strokeWidth={2} size={13} />
                  </button>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}
