import { Component } from "react";

import "./index.css";

import healthCircleSmall from "../../Assets/health-circle-small.png";
import { mediaIconsList } from "../../Constants/Footer";
import { headerItems } from "../../Constants/HeaderItems";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import { aboutList } from "../../Constants/About";

import { HiLocationMarker } from "react-icons/hi";
import { BsTelephoneFill } from "react-icons/bs";
import { GrMail } from "react-icons/gr";

import bubbleDesignImage from "../../Assets/footer-bubble-design.png";

export default class Footer extends Component {
  render() {
    return (
      <div className="footer-main">
        <div className="footer-content-card">
          <div className="footer-media-card">
            <img
              src={healthCircleSmall}
              alt="health-circle"
              className="footer-health-circle-image"
            />
            <h1 className="footer-media-main-heading">
              Personal Health Circles
            </h1>
            <ul className="footer-media-list-card">
              {mediaIconsList.map((item, index) => (
                <li key={index} className="">
                  <item.icon className="footer-media-list-item-icon" />
                </li>
              ))}
            </ul>
          </div>
          <div className="footer-find-here-card">
            <h1 className="footer-find-here-heading">FIND HERE</h1>
            <nav className="footer-find-here-list-card">
              {headerItems.map((item, index) => (
                <NavLink
                  key={index}
                  exact
                  to={item.path}
                  className="footer-find-here-list-item">
                  {item.name}
                </NavLink>
              ))}
            </nav>
          </div>
          <div className="footer-about-card">
            <h1 className="footer-about-heading">ABOUT</h1>
            <nav className="footer-about-list-card">
              {aboutList.map((item, index) => (
                <NavLink
                  key={index}
                  exact
                  to={item.path}
                  className="footer-about-list-item">
                  {item.name}
                </NavLink>
              ))}
            </nav>
          </div>
          <div className="footer-find-us-card">
            <h1 className="footer-find-us-heading">FIND US</h1>
            <div className="footer-find-us-address-card">
              <div className="footer-find-us-address-mail-card">
                <GrMail className="footer-find-us-address-mail-icon" />
                <p className="footer-find-us-address-mail-text">
                info@kshemam.co.in
                </p>
              </div>
              <div className="footer-find-us-address-phone-card">
                {" "}
                <BsTelephoneFill className="footer-find-us-address-phone-icon" />
                <p className="footer-find-us-address-phone-text">
                +91-9396746215
                </p>
              </div>
              <div className="footer-find-us-address-location-card">
                {" "}
                <HiLocationMarker className="footer-find-us-address-location-icon" />
                <p className="footer-find-us-address-location-text">
                  Plot No:45, SAKETH MITHILA, KAPRA, HYDERABAD, DIST MEDCHAL,
                  MALKHAJGIRI, Telangana, India, 500062
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bubble-image-card">
          <img
            src={bubbleDesignImage}
            alt="bubble-img"
            className="footer-bubble-image"
          />
        </div>
      </div>
    );
  }
}
