import { Component } from "react";

import personalHealthCircleImage from "../../../Assets/personal-health-circle.png";

import "./index.css";

export default class PersonalHealthCircles extends Component {
  render() {
    return (
      <div className="personal-health-circle-main">
        <div className="personal-health-circle-image-card">
          <img
            src={personalHealthCircleImage}
            alt="personal-health-circle-img"
            className="personal-health-circle-image"
          />
        </div>
      </div>
    );
  }
}
